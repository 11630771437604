<template>
	<main
		v-if="!isLogin"
		class="w-full h-dvh flex flex-col items-center justify-center"
	>
		<div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
			<div class="sm:mx-auto sm:w-full sm:max-w-md">
				<div
					class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 flex flex-col gap-4 min-w-96"
				>
					<img
						class="mx-auto h-20 w-auto"
						src="@/assets/logo.svg"
						alt="Workflow"
					/>
					<div class="sm:mx-auto sm:w-full sm:max-w-md">
						<h2 class="text-center text-3xl font-bold tracking-tight">
							<span class="text-blue-900">Kreisky Academy</span>
						</h2>
					</div>
					<form class="space-y-4">
						<div>
							<label
								for="email"
								class="block text-sm font-medium text-gray-700"
							>
								Correo
							</label>
							<div class="mt-1">
								<input
									v-model="loginForm.email"
									id="email"
									name="email"
									type="email"
									autocomplete="email"
									required=""
									class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
								/>
							</div>
						</div>
						<div>
							<label
								for="password"
								class="block text-sm font-medium text-gray-700"
							>
								Contraseña
							</label>
							<div class="mt-1">
								<input
									v-model="loginForm.password"
									id="password"
									name="password"
									type="password"
									autocomplete="current-password"
									required=""
									class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
								/>
							</div>
						</div>
						<div>
							<button
								@click.prevent="login"
								type="submit"
								class="transition-all border rounded-md shadow-sm py-2 px-4 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 border-transparent bg-blue-900 text-white hover:bg-blue-700 focus:ring-blue-500 w-full"
							>
								Iniciar Sesión
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</main>
	<router-view v-else />
</template>

<script>
import { globalStore } from "./store";

export default {
	name: "App",
	components: {},
	props: {},
	data() {
		return {
			gStore: globalStore(),
			isLogin: false,
			currentUser: {},
			loginForm: {
				email: "",
				password: "",
			},
			users: [
				{
					id: 1,
					names: "Kreisky Academy",
					email: "kreisky@academy.com",
					level: "GOLD",
					password: "123456",
				},
				{
					id: 2,
					names: "Juan Florián",
					email: "jhongt796@gmail.com",
					level: "GOLD",
					password: "123456",
				},
				{
					id: 3,
					names: "Daniele Nadalin",
					email: "danielenadalin12@gmail.com",
					level: "GOLD",
					password: "Dj3Bi3My7Uk9Lw0",
				},
				{
					id: 4,
					names: "Renato Castro",
					email: "renatocastromonzon@gmail.com",
					level: "GOLD",
					password: "Jx0Ym1Wa9Xb2Ox6",
				},
				{
					id: 5,
					names: "Johana Zazil Ha Ponce",
					email: "drjeponceh@gmail.com",
					level: "GOLD",
					password: "Ri8Cy3Rv8Uu0Ge8",
				},
				{
					id: 6,
					names: "Gilberto Benítez",
					email: "enribenes@gmail.com",
					level: "GOLD",
					password: "Ui9Rs1Uf0Wz7Ww8",
				},
				{
					id: 7,
					names: "Liceth Patarroyo",
					email: "lorena.patarroyo@gmail.com",
					level: "GOLD",
					password: "Ba3Ry5Kw2On8Ol8",
				},
				{
					id: 8,
					names: "Diego Felipe Marca Ticona",
					email: "diegomarca94@gmail.com",
					level: "GOLD",
					password: "Po6Ie7Js7Go0Gn4",
				},
				// {
				// 	id: 9,
				// 	names: "Fernando Munar",
				// 	email: "femunar@hotmail.com",
				// 	level: "GOLD",
				// 	password: "Ah8Fv5Js1Nc7Iz3",
				// },
				{
					id: 10,
					names: "Mauricio Castaño",
					email: "drmauricioce@gmail.com",
					level: "GOLD",
					password: "Ql8Rc8La3Fb6Qi2",
				},
				{
					id: 11,
					names: "Carlos Camacho",
					email: "carloscamacho60@hotmail.com",
					level: "GOLD",
					password: "An7Ef9Hs1Jt1Pz5",
				},
				{
					id: 12,
					names: "Israel Atoche",
					email: "aries_diana99@hotmail.com",
					level: "GOLD",
					password: "Nz7Lq5Za7It6Jd3",
				},
				{
					id: 13,
					names: "Vanessa",
					email: "mirtha.ledesma@gmail.com",
					level: "GOLD",
					password: "Mw9Bq1Ol1Oj6Wd3",
				},
				{
					id: 14,
					names: "Alexander Rodriguez",
					email: "jorgear12@gmail.com",
					level: "GOLD",
					password: "Ts1Jk4Az8Jm5Em0",
				},
				{
					id: 15,
					names: "Mendieta Cedeño Carlos",
					email: "mendietaharvey@gmail.com",
					level: "GOLD",
					password: "Wt3Hu2Ey9Wy7Zb0",
				},
				{
					id: 16,
					names: "Pedro Javier Gutierrez",
					email: "pedro_cirugia@yahoo.com",
					level: "GOLD",
					password: "Yx4Iy6Es2Xl3Gq0",
				},
				{
					id: 17,
					names: "Camilo Torres",
					email: "catorres18@yahoo.es",
					level: "GOLD",
					password: "q63poKm9MJcP",
				},
				{
					id: 18,
					names: "Roberto Guerra",
					email: "robertomiguelgs@hotmail.com",
					level: "GOLD",
					password: "R0bertoGuerr4+",
				},
				{
					id: 19,
					names: "Golden Access",
					email: "kreyskyacademy_gold@yahoo.es",
					level: "GOLD",
					password: "12345678",
				},
				{
					id: 20,
					names: "Lucero",
					email: "lucero_1410@hotmail.com",
					level: "GOLD",
					password: "@Luc3r0123*",
				},
				{
					id: 21,
					names: "Pedro Gutierrez",
					email: "pedro_cirugia@yahoo.com",
					level: "GOLD",
					password: "@P3dr0123*",
				},
				{
					id: 22,
					names: "Alexandra Garcia Cordoba",
					email: "cirujanaplasticagarcia@gmail.com",
					level: "GOLD",
					password: "@Alexandra123*",
				},
				{
					id: 23,
					names: "Edmundo Ziede",
					email: "edmundoziede@gmail.com",
					level: "GOLD",
					password: "@Edmundo123*",
				},
				{
					id: 24,
					names: "Jeniffer Fallas Guevara",
					email: "ladradelasmisses@gmail.com",
					level: "GOLD",
					password: "@Jeniffer123*",
				},
				{
					id: 25,
					names: "Andrea Halliday R",
					email: "andreahallidayr@hotmail.com",
					level: "GOLD",
					password: "@Andrea123*",
				},
				{
					id: 26,
					names: "Jane Sthefanny Valderrama Lazarte",
					email: "drajvalderrama@gmail.com",
					level: "GOLD",
					password: "@Jane123*",
				},
				{
					id: 27,
					names: "Doctor(a)",
					email: "irvingomezbazurto@gmail.com",
					level: "GOLD",
					password: "@Irvin123*",
				},
				{
					id: 28,
					names: "Doctor(a)",
					email: "astrid.abrego@gmail.com",
					level: "GOLD",
					password: "@Astrid123*",
				},
				{
					id: 29,
					names: "Damaris",
					email: "dra.damarisromero@gmail.com",
					level: "GOLD",
					password: "@Damaris123*",
				},
				{
					id: 30,
					names: "Jesus Ricardo Escamilla Martinez",
					email: "ginecoescamilla@gmail.com",
					level: "GOLD",
					password: "@Jesus123*",
				},
				{
					id: 31,
					names: "Facundo Jose Livio",
					email: "facundolivio@hotmail.com",
					level: "GOLD",
					password: "@Facundo123*",
				},
			],
		};
	},
	computed: {},
	watch: {},
	methods: {
		login() {
			const user = this.users.find(
				(user) =>
					user.email === this.loginForm.email &&
					user.password === this.loginForm.password
			);
			console.log({ user, loginForm: this.loginForm });
			if (user) {
				this.isLogin = true;
				this.currentUser = user;
				this.gStore.setUser(user);
				// Set in local storage id of user
				// localStorage.setItem("user", user.id);
			} else {
				alert("Usuario o contraseña incorrectos");
			}
		},
	},
	beforeCreate() {},
	created() {},
	beforeMount() {},
	mounted() {
		const store = globalStore();
		const user = store.getUser();
		if (user) {
			// alert(user);
			this.isLogin = true;
			this.currentUser = user;
		}
	},
	beforeUpdate() {},
	updated() {},
	beforeUnmount() {},
	unmounted() {},
};
</script>

<style scoped></style>
